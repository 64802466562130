// import { TimeIcon } from "@chakra-ui/icons";
// eslint-disable-next-line
import { Box, Skeleton, Text } from "@chakra-ui/react";
import GoogleDataStudio from "components/GoogleDataStudio/GoogleDataStudio";
import Tableau from "components/Tableau/Tableau";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetMeQuery } from "redux/services/apiService";

import {
    useCreateViewCountMutation,
    useLazyGetMenusQuery
} from "redux/services/menu";
import slugify from "slugify";

// import { today } from "utils/constants";
// import { decode } from "utils/hash";

type Props = {};
const DynamicDashboard: React.FC<Props> = (): JSX.Element => {
    const [createViewCount] = useCreateViewCountMutation()
    const params = useParams();
    const [
        trigger,
        { data: menus = [] }] = useLazyGetMenusQuery();
    const parentMenu = params.parent_menu;
    const subMenu = params.menu;
    const getDesc = (param) => {
        var desc = "";
        menus.some((m) =>
            m.children.some((c) => {
                if (c.name.replaceAll(" ", "-") === param) {
                    desc = c.description;
                }
                return desc;
            })
        );
        return desc;
    };
    const { isLoading } = useGetMeQuery();

    const id_post = menus?.filter((e) => slugify(e.name, { lower: true }) === parentMenu)[0]
        ?.children.filter((e) => slugify(e.name, { lower: true }) === subMenu)[0]?.id

    const [offsideWidth, setOffsideWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setOffsideWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [offsideWidth]);

    useEffect(() => {
        trigger();
        if (id_post) {
            createViewCount(
                {
                    "post_id": id_post
                }
            )
        }
        // eslint-disable-next-line
    }, [id_post, params]);

    const RenderEmbed = () => {
        const sourceMenu = menus
            .filter((e) => slugify(e.name, { lower: true }) === parentMenu)[0]
            ?.children.filter((e) => slugify(e.name, { lower: true }) === subMenu)[0]?.source;

        if (!sourceMenu) {
            return <></>;
        }
        if (sourceMenu.includes("tableau")) {
            return (
                <>
                    <Tableau url={sourceMenu} containerId={params.source} width={offsideWidth} />
                </>
            );
        } else if (sourceMenu.includes("datastudio")) {
            return (
                <>
                    <GoogleDataStudio source={sourceMenu} />
                </>
            );
        } else {
            return (
                <>
                    <iframe
                        src={sourceMenu}
                        title="media social embeded"
                        style={{ overflow: "hidden", width: "100%" }}
                        height={window.innerHeight - 150 - 90}
                        width="100%"
                    />
                </>
            );
        }
    };

    return (
        <Skeleton isLoaded={!isLoading}>
            <Box mt="4">
                {params?.source !== "bnVsbA==" ? (
                    <Box>
                        {/* <Box
              fontWeight="bold"
              bg="white"
              width="100%"
              pl="2"
              pt="2"
              fontSize="small">
              <TimeIcon /> Last Update : Hari ini {today}
            </Box> */}
                        <RenderEmbed />
                        {getDesc(params.menu) && getDesc(params.menu) !== null ? (
                            <>
                                <Text fontSize="15" fontWeight="bold" color="#C0392B" mt="2" mb="0">
                                    Deskripsi:
                                </Text>
                                <Text fontSize="15" fontWeight="bold" color="#C0392B" mt="0">
                                    {getDesc(params.menu)}
                                </Text>
                            </>
                        ) : null}
                    </Box>
                ) : (
                    <Text fontSize="5xl" fontWeight="bold" color="#C0392B" textAlign="center" mt="20">
                        Coming Soon!
                    </Text>
                )}
            </Box>
        </Skeleton>
    );
};

export default DynamicDashboard;
