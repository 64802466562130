import React, { useEffect, useState } from "react";

type TableauProps = {
    containerId: string;
    url: string;
    width?: number | string;
    height?: number | string;
};
const Tableau: React.FC<TableauProps> = ({
    url,
    containerId,
    width
}) => {
    const [viz, setViz] = useState(null);
    var x = document.getElementById('root');
    const [offsideHeight, setOffsideHeight] = useState(window.innerHeight);
    const initViz = () => {
        // @ts-ignore
        const { tableau } = window;
        if (tableau) {
            let vizDiv = document.getElementById(containerId);
            if (viz) {
                viz.dispose();
                setViz(null);
            }
            if (width <= 426) {
                //mobile
                setViz(
                    new tableau.Viz(vizDiv, url, {
                        hideTabs: false,
                        hideToolbar: true,
                    })
                );
            } else {
                //leptop
                setViz(
                    new tableau.Viz(vizDiv, url, {
                        hideTabs: false,
                        hideToolbar: true
                    })
                );
            }
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(initViz, [containerId, x]);

    useEffect(() => {
        const handleResize = () => {
            setOffsideHeight(window.innerHeight);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div
            style={{
                overflow: "scroll",
                height: offsideHeight - 200,
                minHeight: offsideHeight - 200,
                maxWidth: x.offsetWidth,
                display: "flex",
                justifyContent: "center",
            }}
            id={containerId}
        />
    );
};

export default Tableau;
