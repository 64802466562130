import { lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { ChakraProvider } from "@chakra-ui/react";

import { useGetConfigsQuery } from "redux/services/config";
import { selectBrandColor } from "redux/slices/color";
import store, { useAppSelector } from "redux/store";

import theme from "definitions/chakra/theme";
import routes from "routes";

import Layout from "components/Layout/Layout";
import { generateColorScale } from "utils/helpers";

import '@fontsource/barlow';
import '@fontsource-variable/inter';
import '@fontsource-variable/oswald';
import "./i18n";

import "@fontsource/poppins/400.css";
import "@fontsource/poppins/600.css";
import "styles/global.css";

const LoginPage = lazy(() => import("pages/auth/login"));

const AppRoute = () => {
    const { data: config } = useGetConfigsQuery();
    let brandColor = useAppSelector(selectBrandColor);
    brandColor = config?.data[0]?.color;
    const brandColorScale = generateColorScale(brandColor);
    // const fontFamily = "'Inter Variable', sans-serif";
    //   const fontFamily = "'Oswald Variable', sans-serif";
    //   const fontFamily = "'Barlow', sans-serif";
    //   const fontFamily = "Poppins, sans-serif";
    const fontFamily = config?.data[0]?.font_type
    // console.log(config?.data[0]?.font_type, 'config?.data[0]?.font_type')
    const customTheme = {
        ...theme,
        colors: {
            ...theme.colors,
            brand: brandColorScale,
        },
        fonts: {
            ...theme.fonts,
            heading: fontFamily,
            body: fontFamily,
        },
    };

    return (
        <ChakraProvider
            theme={
                //   {
                //   ...theme,
                //   colors: {
                //     ...theme.colors,
                //     brand: brandColorScale,
                //   },
                // }
                customTheme
            }
        >
            <Helmet>
                <meta
                    name="description"
                    content={config?.data[0]?.site_desc}
                />
                <meta name="title" content={config?.data[0]?.site_name} />
                <title>{config?.data[0]?.site_name}</title>
            </Helmet>

            <BrowserRouter>
                <Routes>
                    <Route
                        path="/auth/login"
                        element={
                            <Suspense fallback={<>Loading...</>}>
                                <LoginPage />
                            </Suspense>
                        }
                    />

                    <Route element={<Layout />}>
                        {routes.map((route) => (
                            <Route
                                key={route.name}
                                path={route.path}
                                element={
                                    <Suspense fallback={<>Loading...</>}>
                                        {route.component}
                                    </Suspense>
                                }
                            />
                        ))}
                    </Route>
                </Routes>
            </BrowserRouter>
        </ChakraProvider>
    );
};

function App(): JSX.Element {
    return (
        <Provider store={store}>
            <AppRoute />
        </Provider>
    );
}

export default App;
