import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { apiService } from "redux/services/apiService";
import { User } from "redux/services/types";
import { RootState } from "redux/store";

interface IUser extends Omit<User, "id"> {
  isLoggedIn: boolean;
}

const initialState: IUser = {
  name: "no_user",
  email: "no_email",
  organization_name : "no_organization",
  isLoggedIn: false,
  role: "no_role",
  permissions: [],
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setCredential: (state: IUser, { payload }: PayloadAction<User>) => {
      state.name = payload.name;
      state.email = payload.email;
      state.permissions = payload.permissions;
      state.role = payload.role;
      state.isLoggedIn = true;
    },
    removeCreadential: (state) => {
      state.name = initialState.name;
      state.email = initialState.email;
      state.permissions = initialState.permissions;
      state.role = initialState.role;
      state.isLoggedIn = initialState.isLoggedIn;
    },
  },
  extraReducers: (build) => {
    build.addMatcher(
      apiService.endpoints.getMe.matchFulfilled,
      (state, { payload }: { payload: User }) => {
        state.name = payload.name;
        state.email = payload.email;
        state.permissions = payload.permissions;
        state.role = payload.role;
        state.isLoggedIn = true;
      },
    );
  },
});

export const isUserAuthenticated = (state: RootState) =>
  state.rootReducer.user.isLoggedIn;
export const selectUserPermissions = (state: RootState) =>
  state.rootReducer.user.permissions;
export const selectUserRole = (state: RootState) => state.rootReducer.user.role;
export const selectUserName = (state: RootState) => state.rootReducer.user.name;
export const { setCredential, removeCreadential } = userSlice.actions;

export default userSlice.reducer;
