export const BASE_URL =
  process.env.REACT_APP_API_URL || "http://localhost:8000";

const generateRoute = (route: string) => ({
  LIST: `/${route}`,
  CREATE: `/${route}/create`,
  EDIT: `/${route}/edit`,
});

export const ROUTE = {
  USER_SETTING: generateRoute("setting/users"),
  ROLE_SETTING: generateRoute("setting/roles"),
  MENU_SETTING: generateRoute("setting/menus"),
  DATABASE_SETTING: generateRoute("setting/database"),
  PERMISSION_SETTING: generateRoute("setting/permissions"),
  CONFIG_SETTING: generateRoute("setting/config"),
  CCTV: generateRoute("cctv"),
  DATALAKE: generateRoute("datalake"),
  ORGANIZATION_SETTING: generateRoute("setting/organization"),
};

const date = new Date();
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "Mei",
  "Juni",
  "Juli",
  "Agust",
  "Sept",
  "Okt",
  "Nov",
  "Des",
];
export const today =
  date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear();
