import { lazy } from "react";

const Datalake = lazy(() => import("pages/datalake"));
const CreateDatalake = lazy(() => import("pages/setting/Datalake"))
const CreateDatabase = lazy(() => import("pages/setting/Database"))
const Dashboard = lazy(() => import("pages/dashboard"));
const Cctv = lazy(() => import("pages/cctv/CctvPage"));
const ListUser = lazy(() => import("pages/setting/UserSetting"));
const CreateUser = lazy(() => import("pages/setting/UserSetting/CreateUser"));
const EditUser = lazy(() => import("pages/setting/UserSetting/EditUser"));
const Infografis = lazy(() => import('pages/infografis'))
const SortMenu = lazy(() => import("pages/setting/MenuSetting/SortMenu"))
const OrganizationSetting = lazy(() => import("pages/setting/Organization/OrganizationSetting"));
const EditOrganization = lazy(() => import("pages/setting/Organization/EditOrganization"));
const CreateOrganization = lazy(() => import("pages/setting/Organization/CreateOrganization"));
const ListRole = lazy(() =>
    import("pages/setting/RoleSetting").then((r) => ({ default: r.RoleSetting }))
);
const CreateRole = lazy(() =>
    import("pages/setting/RoleSetting").then((r) => ({ default: r.CreateRole }))
);
const EditRole = lazy(() =>
    import("pages/setting/RoleSetting").then((r) => ({ default: r.EditRole }))
);

const ListMenu = lazy(() => import("pages/setting/MenuSetting/MenuSetting"));
const CreateMenu = lazy(() => import("pages/setting/MenuSetting/CreateMenu"));
const EditMenu = lazy(() => import("pages/setting/MenuSetting/EditMenu"));

const ListPermission = lazy(
    () => import("pages/setting/PermissionSetting/PermissionSetting")
);
const CreatePermission = lazy(
    () => import("pages/setting/PermissionSetting/CreatePermission")
);
const EditPermission = lazy(
    () => import("pages/setting/PermissionSetting/EditPermission")
);

const EditConfig = lazy(() => import("pages/setting/ConfigSetting/EditConfig"));
export {
    Cctv,
    CreateDatabase,
    CreateDatalake,
    CreateMenu,
    CreateOrganization,
    CreatePermission,
    CreateRole,
    CreateUser,
    Dashboard,
    Datalake,
    EditConfig,
    EditMenu,
    EditOrganization,
    EditPermission,
    EditRole,
    EditUser,
    Infografis,
    ListMenu,
    ListPermission,
    ListRole,
    ListUser,
    OrganizationSetting,
    SortMenu,
};
