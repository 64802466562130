import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "redux/store";

interface IMenu {
  id: number | null;
  url: string | null;
  source: string | null;
}

const initialState: IMenu = {
  id: null,
  url: null,
  source: null,
};

const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setActiveMenu: (state: IMenu, { payload }: PayloadAction<IMenu>) => {
      state.id = payload.id;
      state.url = payload.url;
      state.source = payload.source;
    },
    removeActiveMenu: (state) => {
      state.id = null;
      state.url = null;
      state.source = null;
    },
  },
});

export const selectActiveMenuSource = (state: RootState) =>
  state.rootReducer.menu.source;
export const selectUserRole = (state: RootState) => state.rootReducer.user.role;
export const { setActiveMenu, removeActiveMenu } = menuSlice.actions;

export default menuSlice.reducer;
